import React from 'react';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Image from 'reusecore/src/elements/Image';
import Heading from 'reusecore/src/elements/Heading';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import BannerWrapper, {
  BannerObject,
  ExternalLink
} from './bannerSection.style';

import MelbourneImage from 'common/src/assets/image/saas/melbourne.jpg';

const AboutSection = ({
  row,
  col,
  title,
  description,
}) => {
  return (
    <BannerWrapper id="banner_section">
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <FeatureBlock
              title={
                <Heading
                  content="About us"
                  {...title}
                />
              }
              description={
                <div>
                  <Text
                    content="We bring an experienced and common sense approach to any challenge. Our specialisation is in harnessing data and AI to improve commercial results. We encapsulate the qualities of trusted advisor, architect, and builder, which shortens your path from strategy to execution."
                    {...description}
                  />
                  <Text/>
                  <Text
                    content="Melbourne, Australia is our home but we work globally."
                    {...description}
                  />
                  <Text
                    content={<span>Contact us at <ExternalLink href="mailto:enquiry@europa-labs.com">enquiry(at)europa-labs.com</ExternalLink>.</span>}
                    {...description}
                  />
                </div>
              }
            />
          </Box>
        </Box>
      </Container>
      <BannerObject>
        <div className="objectWrapper">
          <Image src={MelbourneImage} alt="Melbourne City" />
        </div>
      </BannerObject>
    </BannerWrapper>
  );
};

AboutSection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
  discountText: PropTypes.object,
  discountAmount: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
};

AboutSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: [1, '70%', '50%', '45%'],
  },
  title: {
    fontSize: ['22px', '34px', '30px', '40px'],
    fontWeight: '700',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px'],
    lineHeight: '1.5',
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '0',
  },
};

export default AboutSection;
